$small-screen-size: 650px;
$mid-screen-size: 1000px;

.sideMenu{
    width: 100%;
    background: white;
    // height: 100vh;
    text-align: center;
    overflow: hidden;
    position: relative;
    .logo {
        padding: 20px 0 15px 0; 
        span {
            font-size: 30px;
            font-weight: 800;
            color: var(--partial-color);
            .logoHeight{
                height: 60px;
                cursor: pointer;
            }
        }
    }
    .menuList {
        display: grid;
        grid-template-rows: repeat(6, 75px);
        align-items: center;
        // justify-content: center;
    //    div {
    //         padding: 5px 0;
    //         font-weight: 500;
    //         cursor: pointer;
    //     }
        // .activeMenu{
        //     text-decoration: underline;
        // }
        .menuTitle{
            color: var(--active-title);
            font-size: 12px;
            font-weight: 500;
        }
        .activeDiv{
            background: var(--secondary-color);
            border-radius: 5px;
            padding: 8px 0;
            margin: 15px;
            cursor: pointer;
        }
        .nonActive{
            margin: 10px;
            padding: 15px 0;
            cursor: pointer;
        }
        .sideImageHeight{
            height: 30px;
        }
        .sideTrainnerImageHeight{
            height: 35px;
        }
        .logout{
            display: flex;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            .subLogout{
                position: absolute;
                bottom: 5px;
            }
        }
        @media screen and (max-width: 650px) {
            .sideImageHeight{
                height: 25px;
            } 
            .menuTitle{
                display: none;
            }
            .activeDiv{
                background: var(--secondary-color);
                margin: 10px;
                border-radius: 5px;
                height: 30px;
                // width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .logoHeight{
        height: 30px !important;
    }
    .sideMenu{
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width: $small-screen-size) and (max-width: $mid-screen-size) {
    .logoHeight{
        height: 50px !important;
    }
}