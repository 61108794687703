$small-screen-size: 650px;
$mid-screen-size: 900px;
.dashboardBg{
    background: var(--secondary-color);
    // padding: 5px 35px 0px 5px;
    @media screen and (max-width: $small-screen-size) {
        overflow-y: hidden;
        overflow-x: hidden;
        padding: 0px 0px 0px 0px;
        margin: 0 10px 10px 10px; 
    }
}
.mainScroll{
    overflow-y: auto;
}
.dueRow{
    display: flex;
}
.dotted{
    // max-width: 50px;
    // white-space: nowrap; 
    // overflow: hidden;
    // text-overflow: ellipsis;
}
.companyNameContainer{
    display: flex;
    padding-right: 10px;
}
.welcome{
    font-size: 23px;
    font-weight: 800;
    padding-right: 10px;
    color: var(--active-title);
}
.nameElipsis{
    font-size: var(--name-size);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    text-transform: capitalize;
}
.dashboardTitle{
    width: 99%;
    // height: 80px;
    height: calc(100vh - 90vh);
    // max-height: 10vh;
    background: white;
    // margin: 10px;
    // border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    justify-content: end;
    
}
@media screen and (max-width: $small-screen-size) {
    .dashboardTitle{
        height: calc(100vh - 93vh);
        background: none;
        display: flex;
        align-items: center;
        padding: 0 0 0 10px;
        justify-content: end;
    }
} 
.totalRevenueColor{
    color: rgb(139, 22, 108) !important;
}
.monthlyRevenueColor{
    color: rgb(22, 139, 75) !important;
}
.pendingRevenueColor{
    color: rgb(255, 0, 0) !important;
}
.chatIcon{
    img{
        padding: 0 5px;
    }
}
.disable{
    pointer-events: none;
    opacity: 0.7;
}
.splitDash{
    display: grid;
    grid-template-columns: 68% 32%;
    max-height: 90vh;
    @media screen and (max-width: $small-screen-size) {
        display: grid;
        grid-template-columns: repeat(1, 100%); /* Two columns, each taking 50% of the container's width */
        grid-gap: 10px;
      }
}
.innerPadding{
    overflow-x: hidden;
    padding: 0px 10px 0 10px;
}
.titleContainer{
    margin: 10px 0 10px 15px;
}
.subTitle{
    font-weight: 900;
    margin-top: 5px;
    color: var(--active-title);
    // margin-left: 10px;
}
.availableTrainees{
    background: white;
    // padding: 5px;
    border-radius: 5px;
}
.mainCard{
    width: 100%;
    // margin: 10px;
    border-radius: 5px;
    // display: flex;
    align-items: center;
    // padding: 0 0 0 10px;
    .summaryContainer{
        // background: white;
        border-radius: 5px;
        padding: 5px 0 0 10px;
        // color: var(--active-title);
        height: calc(100vh - 80vh);
    }
    @media screen and (max-width: $small-screen-size) {
        .summaryContainer{
            padding: 0;
            height: auto;
        }
    }
    .cardMain{
        // height: 160px;
        // background: white;
        display: grid;
        // grid-template-columns: 25% 25% 25% 25% 25% 25%;
        grid-template-columns: repeat(5, 20%);
        margin: 0 0px;
        grid-gap: 2px;
        height: calc(100vh - 80vh);
    }
    @media screen and (max-width: $small-screen-size) {
        .cardMain{
            display: grid;
            grid-template-columns: repeat(2, 50%);
            margin: 0 10px;
            height: auto;
        }
      }
    .cardList{
        // border: 2px solid var(--primary-color);
        // color: white;
        // background: rgba(255, 166, 0, 0.696);
        // background: rgb(251,128,0);
        background: #ffffff;
        box-shadow: 0 0 1px;
        border-radius: 5px;
        margin: 10px 5px;
        padding: 10px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        .titleContainer{
            margin: 5px 0;
        }
        .cardName{
            font-size: 12px;
            color: rgb(86, 84, 84);
            font-weight: 700;
            margin: 10px 0;
        }
        .cardValue{
            font-weight: 900;
            color: var(--active-title);
        }
        // .circleDesignTop{
        //     background: #f1f2fe90;
        //     height: 100px;
        //     width: 100px;
        //     position: absolute;
        //     right: -40px;
        //     top: -8px;
        //     border-radius: 50px
        // }
        // .circleDesignBtm{
        //     background: #f1f2fe90;
        //     height: 100px;
        //     width: 100px;
        //     position: absolute;
        //     right: -11px;
        //     bottom: -32px;
        //     border-radius: 50px
        // }
    }
    // .cardOne{
    //     background: #ffffff;
    // }
    // .cardTwo{
    //     background: rgb(47,197,255);    
    // }
    // .cardThree{
    //     background: rgb(31, 217, 186);
    // }
    // .cardFour{
    //     background: rgb(105, 216, 26);
    // }
    // .cardFive{
    //     // background: #ff43cd;
    //     // background: #f92735;
    //     background: #ff2727;
    // }
    .innerSubContainer{
        background: var(--secondary-color);
        padding: 5px 0 0 10px;
        // height:50vh;
        width: 100%;
        // margin-top: 10px;
        display: grid;
        // grid-template-columns: 40% 60%;
        grid-template-columns: 50% 50%; 
        @media screen and (max-width: $small-screen-size) {
            display: grid;
            grid-template-columns: repeat(1, 100%); /* Two columns, each taking 50% of the container's width */
            grid-gap: 10px;
          }
        .currentMonthStudent{
            // height: 100%;
            width: 100%;
            // height: 65vh; 
            height:  calc(100vh - 40vh);
            background: white;
            border-radius: 5px;
            // padding-top: 7px;
            overflow-y: auto;
            scrollbar-width: thin;
            // display: grid;
            // grid-template-rows: 35% 65%;
            .currentMonthStudentContainer{
                display: flex;
                justify-content: center;
            }
            .batch{
                border: 10px solid var(--secondary-color);
                border-radius: 5px;
                margin: 10px;
                width: 30%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                .currentMonthLength{
                    font-weight: 900;
                    font-size: 32px;
                }
            }
            .trainersList{
                font-size: var(--name-size);
                border: 2px solid var(--grey-color);
                padding: 5px;
                margin: 3px;
                border-radius: 5px;
                display: grid;
                // justify-content: space-between;
                grid-template-columns: 50% 30% 20%;
                padding: 0 0px 0 10px;
                text-transform: capitalize;
            }
            @media screen and (max-width: $small-screen-size) {
                .trainersList{
                    grid-template-columns: 38% 40% 30%
                }
            }
        }
        .needToDecide{
            height: 100%;
            width: 97%;
            border-radius: 5px; 
            margin-left: 10px;
            display: grid;
            // grid-template-columns: 70% 30%;
            .availableTrainees{
                background: white;
                // padding: 5px;
                border-radius: 5px;
            }
            @media screen and (max-width: $small-screen-size) {
                // .needToDecide{
                    height: 100%;
                    margin-left: 0px;
                    width: 100%;
                    border-radius: 5px;
                // }
            }
        }
        .nameTitle{
            font-weight: 900;
            margin-top: 5px;
            padding-left: 10px;
        }
    }
    @media screen and (max-width: $small-screen-size) {
        .innerSubContainer{
            padding: 0;
        }
    }
}
.subContainer{
    border-radius: 5px;
    background: white;
}

.rightContainer{
    margin: 10px 0px 0px 15px;
    width: 92%;
    .dueContainer{
        width: 100%;
        // height: 71vh;
        height: calc(100vh - 28vh);
        background: white;
        border-radius: 5px;
        // padding: 10px;
        overflow-y: auto;
        scrollbar-width: thin;
        .due{
            padding: 5px 0 0 5px;
        }
        .dueList{
            display: grid;
            // grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
            grid-template-columns: 60% 40%;
            // justify-content: space-between;
            border: 2px solid var(--grey-color);
            margin-top: 5px;
            padding: 5px;
            border-radius: 5px;
            .dueAmtRow{
                display: grid;
                // grid-template-columns: 54% 46%;
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
                text-align: center;
            }
        }
    }
    @media screen and (max-width: $small-screen-size) {
        .dueContainer{
            width: 100%;
        }
    }
    .fitnessGiff{
        margin: 15px 0px 0px 0px;
        border-radius: 5px;
    }
}

@media screen and (max-width: $small-screen-size) {
    .mainCard{
        margin: 0px;
    }
    .rightContainer{
        margin: 0px;
        width: 100%;
    }
    .dueContainer{
        height: auto !important;
        max-height: calc(100vh - 31vh);
        padding-bottom: 15px;
    }
    .currentMonthStudent{
        height: auto !important;
        max-height:  calc(100vh - 40vh);
    }
}
.clickAction{
    display: flex;
    background: white;
    text-align: center;
    // display: flex;
    // justify-content: center;
    padding: 10px;
    margin: 10px 0 0 0px;
    border-radius: 5px;
    // height: 10vh;
    height: calc(100vh - 90vh);
    .foodList{
        // height: 60px;
        width: 100%;
        background: var(--secondary-color);
        display: flex;
        color: var(--active-title);;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        border-radius: 5px;
        font-size: 13px;
        cursor: pointer;
        // margin-r: 10px;
    }
    @media screen and (max-width: $small-screen-size) {
        .foodList{
            // width: 100%; 
            font-size: 10px;
        }
    }
}
.currentMonStudent{
    margin-top: 10px;
}
.companyContainer{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--popup-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    .companyPopup{
        background: white;
        // height: 200px;
        width: 300px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 10px;
        .closeCompanyPopup{
            position: absolute;
            top: 0;
            left: 0;
            padding:3px 0 0 5px;
            .companyCross{
                border: none;
                height: 30px;
                width: 30px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        form{
            .conpanyContainer{
                padding: 5px 0 1.5rem 0;
            }
            text-align: center;
            .companyNameLabel{
                margin: 10px;
                color: var(--active-title);
                font-weight: 700;
            }
            input{
                width: 190px;
                height: 30px;
                border-radius: 5px;
                margin: .3rem 0 .8rem 0;
                border: 2px solid var(--active-title);
            }
            button{
                height: 35px;
                width: 60px;
                background: var(--active-title);
                color: white;
                border: none;
                border-radius: 5px;
                border: none;
                cursor: pointer;
            }
        }
    }
}
* {
    scrollbar-width: thin;
    // scrollbar-color: #160851 white; /* thumb color and track color */
}
@media screen and (max-width: $small-screen-size) {
    .welcome{
        font-size: 17px;
    }
    .mobileMail{
        display: none;
    }
    .chatIcon{
        text-align: center;
    }
    .dueList{
        display: grid;
        grid-template-columns: 55% 45% !important;
    }
    .dueAmtRow{
        display: grid;
        grid-template-columns: 60% 40%;
    }
}