.supportBtn{
    height: 40px;
    width: 150px;
    background: var(--grey-color);
    border: none;
    color: var(--active-title);
    font-weight: 900;
    border-radius: 5px;
    cursor: pointer;
}

.feedback:focus{
    outline: none;
}