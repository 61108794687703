$small-screen-size: 650px;
$mid-screen-size: 900px;

.registrationPopup{
    position: absolute;
    top: 0;
    left: 0;
    background: var(--popup-bg);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .registrationForm{
        position: relative;
        width: 30%;
        // height: 94vh;
        border-radius: 5px;
        background: white;
        display: flex;
        align-items: center;
        padding:10px 0;
        form{
            text-align: center;
            width: 100%;
            .titleContainer{
              width: 100%;
              margin: 0;
            }
            .startContainer{
              width: 100%;
              display: flex;
              justify-content: center;
              padding: 0;
              span{
                font-size: 13px;
              }
              .emailInput{
                text-align: end;
                span{
                  font-size: 10px;
                }
              }
            }
           .formTitle{
            font-weight: 800;
            font-size: 23px;
            color: var(--active-title);
           } 
           .paidAmount{
            height: 15px;
            margin-top: 5px;
           }
        }
        input{
            width: 250px;
            height: 27px;
            margin: 4px 0;
            border: 2px solid var(--grey-color);
            border-radius: 5px;

        }
        textarea{
            width: 250px;
            height: 50px;
            margin: 4px 0;
            border: 2px solid var(--grey-color);
            border-radius: 5px;
            resize: none;
        }
        // textarea:focus, input:focus{
        //     border: 2px solid var(--primary-color);
        //     outline: none;
        // }
        button{
            height: 40px;
            width: 150px;
            color: white;
            background: var(--active-title);
            border: none;
            border-radius: 5px;
            font-weight: 900;
        }
        .cloaseButton{
            position: absolute;
            right: 0;
            top: 0;
            background: var(--grey-color);
            margin: 5px;
            cursor: pointer;
            border: none;
            height: 30px;
            width: 30px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }
        .toggleButton{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .emiToggle{
            // background: rgb(233, 230, 230);
            border: 2px solid var(--grey-color);
            height:25px;
            width:50px;
            position:relative;
            border-radius: 5px;
        }
          
          .offToggle {
            position: absolute;
            content: "";
            height: 17px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: var(--popup-bg);
            -webkit-transition: .4s;
            border-radius: 5px;
            transition: .4s;
          }
          .onToggle{
            position: absolute;
            content: "";
            height: 17px;
            width: 20px;
            right: 4px;
            bottom: 4px;
            background-color: var(--active-title);
            border-radius: 5px;
            -webkit-transition: .4s;
            transition: .4s;
          }
          .emiLable{
            padding-left: 5px;
            font-weight: 500;
            color: var(--active-title);
          } 
        }       
        @media screen and (max-width: $small-screen-size) {
          .registrationForm{
              width: 90%;
              // height: 85vh;
          }
        }
        @media screen and (min-width: $small-screen-size) and (max-width: $mid-screen-size) {
          .registrationForm{
              width: 50%;
              // height: 60vh;
          }
        }
}

@media screen and (max-width: $small-screen-size) {
    
}