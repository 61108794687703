.header{
    height: 70px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    // border-bottom: 2px solid var(--logo-color);
    position: fixed;
    background: white;
    z-index: 1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    div{
        margin: 0 10px;
        .button{
            height: 50px;
            width: 150px;
            background: linear-gradient(135deg, #6e8efb, #a777e3);
            color: white;
            font-weight: bold;
            font-size: 1rem;
            border: none;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: transform 0.3s ease, background 0.3s ease;
            outline: none;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        }
        .button:hover {
            transform: scale(1.05);
            background: linear-gradient(135deg, #a777e3, #6e8efb);
        }

        .button:active {
            transform: scale(0.98);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        }
    }
}

.button{
    height: 50px;
    width: 150px;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
    outline: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.button:hover {
    transform: scale(1.05);
    background: linear-gradient(135deg, #a777e3, #6e8efb);
}

.button:active {
    transform: scale(0.98);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.startBtn{
    height: 50px;
    width: 180px;
    background: var(--active-title);
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    animation: shake 0.5s;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    transition: transform 0.3s ease, background 0.3s ease;
    outline: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    animation-iteration-count: infinite;
}
.startBtn:hover {
    animation-play-state: paused;
  }
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
.spacingTop{
    height: 100px;
}
.brandName{
    background: #f3f1fb;
    height: 100px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    font-weight: bold;
    background: linear-gradient(90deg, var(--text), var(--subText));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.ani{
    animation: gradientSlide 4s ease-in-out infinite, pulse 2s ease-in-out infinite;
}
  
  @keyframes gradientSlide {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
    50% {
      transform: scale(1.05);
      text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    }
  }

  .titleText{
    font-weight: bold;
    background: linear-gradient(90deg, var(--text), var(--subText));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  .detailColor{
    color: #506ac0;
  }
  .border{
    border: 2;
  }
  .detailTitle {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInSlideUp 1.5s ease forwards;
  }
  
  @keyframes fadeInSlideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
.bodyContainer{
    text-align: center;
    margin-bottom: 3rem;
    .titleBox{
        display: grid;
        grid-template-columns: 60% 40%;
        margin-bottom: 50px;
        .leftContainer{
            margin: 10px;
            .contents{
                text-align: center;
            }
            .titleSize{
                font-size: 3rem;
            }
            
            p{
                margin: 0 3rem;
            }
            @keyframes shadowSlide {
                0% {
                    text-shadow: -5px 0px 10px #a777e3;
                }
                50% {
                    text-shadow: 5px 0px 10px #6e8efb;
                }
                100% {
                    text-shadow: -5px 0px 10px #a777e3;
                }
            }

            .payAni {
                font-weight: bold;
                background: linear-gradient(90deg, var(--text1), var(--text2), var(--text3), var(--text4));
                background-size: 300%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: colorCycle 6s ease-in-out infinite;
                }

                @keyframes colorCycle {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
                }
            
        }
    }
    @media screen and (max-width: 700px) {
        .titleBox{
            display: grid;
            grid-template-columns: 1fr;
            .leftContainer{
                margin-bottom: 40px;
            }
        }
    }
    .listContainer{
        display: flex;
        justify-content: center;
    }
    .cardContainer{
        display: flex;
        justify-content: center;
        width: 90%;
        .card{
            display: grid;
            grid-template-columns: 50% 50%;
            margin: 30px 0;
            align-items: center;
            .detailsStart, .detailsEnd{
                text-align: start;
                h3{
                    margin: 0;
                }
            }
            .imgDiv{
                text-align: center;
                .imgHeight{
                    height: 200px;      
                }
                .imgWithBorder{
                    height: 200px;
                    border: 2px solid;
                    border-image: linear-gradient(90deg, #506ac0, var(--subText)) 1;
                }
            }
        }
        .cardColor{
            // background: #e5ebfe;
            background: linear-gradient(90deg, #cdd8ff, #e4cdff);
            padding: 20px;
            border-radius: 5px;
        }
        .noCardColor{
            padding: 20px;
        }
        @media screen and (max-width: 1000px) {
            .card{
                display: grid;
                grid-template-columns: 100%;
                margin: 0px;
                align-items: center;
                .detailsStart{
                    text-align: start;
                    padding: 20px;
                    order: 1;
                }
                .detailsEnd{
                    text-align: end;
                    padding: 20px;
                    order: 1;
                }
                .imgDiv{
                    order: 2;
                    .imgHeight,
                    .imgWithBorder{
                        height: 160px;
                    }
                }
            }
        }
    }
    
}
.feedbackMain{
    text-align: center;
    margin: 50px 0 90px 0;
    .feedContainer{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(362px,1fr));
        gap: 30px;
        margin: 30px 10px 10px 10px;
        text-align: center;
        .card{
            border: 2px solid var(--active-title);
            border-radius: 5px;
            padding: 5px;
            img{
                height: 120px;
            }
            h3{
                margin-bottom: 0;
            }
            p{
                margin: 10px 10px;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .feedContainer{
            margin: 30px 0px 10px 0px;
            grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
        }
    }
}
.getCustomize{
    text-align: center;
    // background: rgb(243, 241, 251);
    padding: 20px 0 60px 0;
    background: linear-gradient(90deg, var(--text), var(--subText));
    margin: 20px;
    .submitBtn{
        display: flex;
        justify-content: center;
        button{
            border: 1px solid white;
        }
    }
    h2{
        color: white;
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        span{
            color: rgb(211, 22, 228);
        }
    }
    
    input{
        height: 35px;
        width: 300px;
        margin: 5px;
        border: 1px solid white;
    }
    textarea:focus, input:focus{
        outline: none;
    }
    textarea{
        width: 301px;
        height: 50px;
        border: 1px solid white;
    }
}
@media screen and (max-width: 750px) {
    .getCustomize{
        margin: 0;
    }
}
.activeCount{
    display: flex;
    justify-content: center;
    margin: 0 0 5rem 0;
    .activeNumber{
        text-align: center;
    }
    .countContainer{
        display: flex;
        margin-top: 2rem;
        .count{
            margin: .4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5rem;
            width: 4rem;
            background-color: #cdd8ff;
            text-align: center;
            font-weight: 500;
            width: 10rem;
            border-top: .7rem solid var(--text);
            padding: .6rem;
            .value{
                font-weight: 900;
                font-size: 2rem;
            }
        }
    }
}
