.App {
  text-align: center;
}
:root {
  --primary-color: #A4EDE5;
  --logo-color: #5773d4;
  --text: #6e8efb;
  --subText: #a777e3;
  --secondary-color: #f1f2fe;
  --partial-color: rgb(132, 32, 194);
  --button-color: #4efae6;
  --grey-color: #f1f2fe;
  --popup-bg: rgba(0, 0, 0, 0.422);
  --active-bg: #afde5e;
  --active-title: #2a167a;
  --name-size: 12px;

  --text1: #6e8efb;
  --text2: #a777e3;
  --text3: #f47c7c;
  --text4: #ffd56b;
}
.pageSplit{
    height: 100vh;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 10% 90%;
    background: var(--secondary-color);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .pageSplit{
    height: 100vh;
    display: grid;
    grid-template-columns: 12% 88%;
    background: var(--secondary-color);
    overflow-y: hidden;
  }
}