.loadingBg{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: var(--popup-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
.loader {
    width: 100px;
    aspect-ratio: 1;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    // filter: blur(1px) contrast(1);
    mix-blend-mode: darken;
  }
  .loader:before,
  .loader:after{ 
    content: "";
    grid-area: 1/1; 
    width: 40px;
    height: 40px;
    background: var(--active-title);
    animation: l7 2s infinite;
  }
  .loader:after{ 
    animation-delay: -1s;
  }
  @keyframes l7{
    0%   {transform: translate(   0,0)}
    25%  {transform: translate(100%,0)}
    50%  {transform: translate(100%,100%)}
    75%  {transform: translate(   0,100%)}
    100% {transform: translate(   0,0)}
  }