$small-screen-size: 650px;
$mid-screen-size: 900px;

.updatePay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--popup-bg);
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .paymentPopup{
        position: absolute;
        height: 250px;
        width: 30%;
        background: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .companyCross{
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        height: 30px;
        width: 30px;
        background: var(--grey-color);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .movePopup{
        position: absolute;
        // height: 150px;
        width: 30%;
        background: white;
        border-radius: 5px;
        padding: 0 20px;
        height: 150px;
        display: flex;
        align-items: center;
        // display: flex;
        .moveBtn{
            button{
                height: 33px;
                width: 80px;
                border: none;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: $small-screen-size) {
        .paymentPopup{
            width: 90%;
        }
        .movePopup{
            width: 70%;
        }
      }
      @media screen and (min-width: $small-screen-size) and (max-width: $mid-screen-size) {
        .paymentPopup{
            width: 50%;
        }
        .movePopup{
            width: 40%;
        }
      }
    
    .paymentForm{
        margin-top: 25px;
        button{
            cursor: pointer;
        }
    }
    .details{
        width: 100%;
        text-align: center;
        input{
            height: 30px;
            width: 200px;
            border: 2px solid var(--grey-color);
            outline: none;
            border-radius: 5px;
        }
        button{
            height: 33px;
            width: 50px;
            border: none;
            border-radius: 5px;
            margin-left: 5px;
            background: var(--grey-color);
        }
        span{
            font-weight: 700;
        }
        .movePopupDetail{
            font-weight: 500;
            margin: 0 0 20px 0;
        }
        .title{
            font-weight: 900;
            margin: 0 0 20px 0;
            color: var(--active-title);
        }
    }
}