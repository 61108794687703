.commonBg{
    background: var(--popup-bg);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
}
.commpnAlert{
    // height: 150px;
    width: 250px;
    border-radius: 5px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    .commonContent{
        padding: 0 0 10px 0;
        .resetTitle{
            font-weight: 600;
            font-size: 1.2rem;
        }
        input{
            height: 1.5rem;
            width: 13rem;
            border-radius: .3rem;
            border: .1rem solid var(--active-title);
        }
    }
    .commonContentBtn{
        margin:10px 0 0 0;
        button{
            background:  var(--active-title);
            color: white;
            width: 70px;
            height: 25px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .commonBtn{
        margin:10px 0 0 0;
        display: flex;
        justify-content: space-evenly;
        button{
            background:  var(--active-title);
            color: white;
            width: 70px;
            height: 25px;
            border: none;
            border-radius: 5px;
        }
    }
}