.mainContainer{
    margin: 10px;
    padding: 20px;
    background: white;
    // height: 90vh;
    border-radius: 5px;
    overflow-y: hidden;
    .RegisterBtn{
        height: 40px;
        width: 200px;
        background: var(--grey-color);
        border: none;
        // color: var(--primary-color);
        color: var(--active-title);
        font-weight: 900;
        border-radius: 5px;
        box-shadow: 1px 1px 1px var(--active-title);
        cursor: pointer;
    }
    .mobileReg{
        display: none;
    }
    @media (max-width: 768px) {
        padding: 10px 20px 0px 20px;
        // margin: 15px 15px 0 15px;
        height: 95vh;
        .RegisterBtn{
            display: none !important;
        }
        .mobileReg{
            position: absolute;
            bottom: 30px;
            right: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: 800;
            height: 50px;
            width: 50px;
            text-align: center;
            border-radius: 25px;
            background: var(--grey-color);
            box-shadow: 2px 2px 2px grey;
        }
    }
}
.nameBox{
    display: none;
    @media (max-width: 768px) {
        text-align: center;
        display: block;
        .pageTitle{
            color: var(--active-title);
            font-weight: 800;
        } 
    }
}