.filterData{
    height: calc(100vh - 82vh);
}
.dataTable{
    height: calc(100vh - 16vh);
    margin-top: .5rem;
}

.userList{
    table{
        border-radius: 5px;
        th{
            border: 1px solid var(--partial-color);
            background: var(--partial-color);
            color: white;
        }
        td{
            border: 1px solid var(--partial-color);
            text-align: center;
        }
    }
}
.actionbtn{
    background: var(--partial-color);
}
.deletebtn{
    font-weight: 500;
    cursor: pointer;
    color: var(--grey-color);
    background: var(--active-title);
    padding: 3px;
    border-radius: 5px;
    padding: 8px 9px;
}
.textbtn{
    font-weight: 700;
    cursor: pointer;
    padding: 4px;
    background: var(--grey-color);
    margin: 4px;
    border: none;
    font-size: 10px;
    width: 50px;
    border-radius: 5px;
}
.userListCheckbox{
    accent-color: var(--active-title);
    height: 15px;
    width: 15px;
    margin: 5px;
    cursor: pointer;
}
.recordAction{
    height: 50px;
    padding: 5px 0 0px;
    display: flex;
    align-items: center;
    div:nth-child(2){
        padding: 0 10px;
    }
}
.activefilter{
    background: var(--active-title);
    color: var(--grey-color);
    box-shadow: 1px 2px 3px #606a50;
    border-radius: 5px;
}
.filterAndSearch{
    display: grid;
    height: 50px;
    grid-template-columns: 70% 30%;
    align-items: center;
    background: var(--grey-color);
    margin-top: 10px;
    border-radius: 5px;
    padding: 0 5px 0 0;
    .userDataFilter{
        display: grid;
        grid-template-columns: repeat(6,15%);
        align-items: center;
        text-align: center;
        color: var(--active-title);
        div:nth-child(6){
           width: 140px;
           input{
            text-align: center;
           }
        }
        div{
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .filterDate{
                height: 30px;
                width: 100%;
                border: none;
                outline: none;
                border-radius: 5px;
                margin-left: 20px;
            }
        }
    }
    .userDataFilterTrainnee{
        display: grid;
        grid-template-columns: repeat(4,15%);
        align-items: center;
        text-align: center;
        color: var(--active-title);
        div:nth-child(4){ 
            width: 140px;
            input{
             text-align: center;
            }
         }
        div{
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .filterDate{
                height: 30px;
                width: 88%;
                border: none;
                outline: none;
                border-radius: 5px;
            }
        }
    }
}

.endSearch{
    text-align: end;
    input{
        border: 2px solid var(--grey-color);
        height: 33px;
        outline: none;
        border-radius: 5px;
        padding: 0px 6px;
        color: var(--active-title);
    }
    input::placeholder {
        color: var(--active-title); /* Change the color to red */
        font-weight: 100;
    }
}
.memberTableTitleGrid{
    display: grid;
    grid-template-columns: 4% 10% 14% 3% 10% 21% 9% 7% 7% 7% 8%;
}
.checkInTableTitleGrid{
    display: grid;
    grid-template-columns: 4% 15% 21% 3% 10% 23% 9% 7% 8%;
}

.customTableTitle{
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @media (max-width: 768px) {
        display: none;
        grid-template-columns: 1fr; // 1 column for mobile
    }
    div{
        font-size: 12px;    
    }
    .customTableHeader{
        font-weight: 700;
        color: var(--active-title);
    }
}
.customTablePayment{
    display: grid;
    grid-template-columns: 20% 30% 20% 15% 15%;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    div{
        font-size: 12px;    
    }
    .customTableHeader{
        font-weight: 700;
        color: var(--active-title);
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.customPaymentContent{
    display: grid;
    grid-template-columns: 20% 30% 20% 15% 15%;
    border-bottom: 2px solid var(--grey-color);
    align-items: center;
    @media (max-width: 768px) {
        grid-template-columns: 1fr; // 1 column for mobile
        padding: 10px 0;
        div{
            display: grid;
            grid-template-columns: 27% 73%;
            text-align: start;
            margin-left: 20px; 
            .labelForMobile{
                display: block;
                font-weight: 800;
            }
        }
        .actionButton{
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }
        .deleteCheckBox{
            text-align: end !important;
        }
        .emptyDiv{
            display: block;
        }
    }
    .labelForMobile{
        display: none;
    }
    div{
        font-size: 12px;    
    }
    .customTableHeader{
        font-weight: 700;
    }
}
.customTableTrainneeTitle{
    display: grid;
    grid-template-columns: 4% 10% 14% 3% 11% 28% 10% 10% 10%;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--active-title);
    @media (max-width: 768px) {
        display: none;
        grid-template-columns: 1fr; // 1 column for mobile
    }
    div{
        font-size: 12px; 
    }
    .customTableHeader{
        font-weight: 700;
    }
}
.tableheader{
    height: 35px;
    background: var(--grey-color);
}
@media (max-width: 768px) {
    .customTableBody{
        height: 65vh !important;
        margin-top: 1.5rem;
    }
    .paymentTableBody{
        margin-top: 16px;
        height: 74vh !important;
    }
}
@media (min-width: 768px) {
    .paymentTableBody{
        height: calc(100vh - 34vh) !important;
    }
}
.customTableBody,.paymentTableBody{
    border: 2px solid var(--grey-color);
    height: calc(100vh - 43vh);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
    scrollbar-width: none;
    text-align: center;
    .customTableContent{
        border-bottom: 2px solid var(--grey-color);
        align-items: center;
        @media (max-width: 768px) {
            grid-template-columns: 1fr; // 1 column for mobile
            div{
                display: grid;
                grid-template-columns: 28% 72%;
                text-align: start;
                margin-left: 20px; 
                .labelForMobile{
                    display: block;
                    font-weight: 800;
                }
            }
            .actionButton{
                display: flex;
                justify-content: center;
                margin: 10px 0;
            }
            .deleteCheckBox{
                text-align: end !important;
            }
            .emptyDiv{
                display: block;
            }
        }
        .labelForMobile{
            display: none;
        }
        div{
            font-size: 12px;  
        }
        .customTableHeader{
            font-weight: 700;
        }
    }
    .customTrainnerContent{
        display: grid;
        grid-template-columns: 4% 10% 14% 3% 11% 28% 10% 10% 10%;
        border-bottom: 2px solid var(--grey-color);
        align-items: center;
        @media (max-width: 768px) {
            grid-template-columns: 1fr; // 1 column for mobile
            div{
                display: grid;
                grid-template-columns: 25% 75%;
                text-align: start;
                margin-left: 20px;   
                .labelForMobile{
                    display: block;
                    font-weight: 800;
                }
                span{
                    text-align: start;
                }
            }
            .actionButton{
                display: flex;
                justify-content: center;
                margin: 10px 0;
            }
            .deleteCheckBox{
                text-align: end !important;
            }
            .emptyDiv{
                display: block;
            }
        }
        .labelForMobile{
            display: none;
        }
        div{
            font-size: 12px; 
            text-align: center;
        }
        .customTableHeader{
            font-weight: 700;
        }
    }
}
.deleteModalOverley{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: var(--popup-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    .deleteModal{
        height: 130px;
        width: 250px;
        background: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .deleteModelContent{
        font-weight: 700;
        color: var(--active-title);
    }
    .deleteModalBtn{
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        button{
            height: 30px;
            width: 60px;
            border-radius: 5px;
            border: none;
            background: var(--grey-color);
            cursor: pointer;
        }
    }
}
.paymentDateFilter{
    height: 30px;
    border: 2px solid var(--grey-color);
    margin: 0 0 0 10px;
    border-radius: 5px;
}
@media (max-width: 768px) {
    .filterAndSearch{
        display: block;
        background: white;
        margin: 15px 0 35px 0;
        padding: 0;
    }
    .userDataFilter{
        display: none !important;
    }
    .userDataFilterTrainnee{
        display: none !important;
    }
    .mobileFilter{
        display: grid;
        grid-template-columns: 40% 60%;
        margin-bottom: 15px;
        select{
            border: 2px solid var(--grey-color);
            height: 35px;
        }
        input{
            border: 2px solid var(--grey-color);
            height: 33px;
            text-align: center;
        }
    }
}
.mobileSpacing{
    padding: 0;
}
.paymentDateFilter{
    width: 140px;
    text-align: center;
}
.noResult{
    font-size: 16px;
    font-weight: 500;
    color: var(--active-title);
}
@media (max-width: 768px) {
    .mobileSpacing{
        padding:10px 0;
    }
    .paymentDateFilter{
        margin: 0;
    }
    .filterData{
        height: calc(100vh - 81vh);
    }
    .endSearch{
        margin-top: 10px;
    }
}
@media (min-width: 768px) {
    .mobileFilter{
        display: none !important;
    }
}