$small-screen-size: 650px;
.loginScrinSplit{
    display: grid;
    grid-template-columns: 60% 40%;
    height: 100vh;
}
.leftScreen{
    // background: #d8d1f0;
    background: linear-gradient(90deg, #cdd8ff, #e4cdff);
    height: 100vh;
    display: flex;
    align-items: center;
    color: var(--active-title);
    .cotes{
        width:  100%;
        display: flex;
        justify-content: center;
        .cotesDesc{
            width: 75%;
        }
    }
}
.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 0px 0 !important;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
}
.carousel .slide {
    min-width: 100%;
    margin: 0;
    height: 450px !important;
    position: relative;
    text-align: center;
}
.rightScreen{
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    .loginContainer{
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        .logoContainer{
            margin: 0 0 1rem 0;
        }
        h1{
            color: var(--active-title);
            margin: 0  0 20px 0;
            font-size: 15px;
        }
        .googleLogin{
            display: flex;
            justify-content: center;
            .googleBtn{
                height: 30px;
                width: 196px;
                margin: 1rem;
                border-radius:  5px;
                border: 2px solid rgb(211, 66, 66);
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                span{
                    margin-left: 10px;
                    font-size: .8rem;
                    font-weight: 700;
                }
            }
        }
        .splitterOr{
            font-weight: 800;
        }
    }
}

.loginTitle{
    font-size: 1.5rem;
    font-weight: 800;
    // color: var(--active-title);
    background: linear-gradient(90deg, var(--text), var(--subText));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.loginLabel{
    margin-top: 1rem;
}
.loginType{
    font-size: 0.9rem;
}
.signInAndUp{
    color: var(--active-title);
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
    font-size: 1rem;
    font-weight: 700;
}
.loginForm{
    margin: 1rem;
    input{
        height: 25px;
        width: 200px;
        border: solid var(--active-title);
        border-radius: 5px;
        margin: .4rem;
    }
    .showAndHideContainer{
        text-align: end;
    }
    .showHidePassword{
        color: var(--active-title);
        font-size: 0.7rem;
        font-weight: 500;
        text-decoration: underline;
        margin: .3rem 2rem 0 0;
        cursor: pointer;
        font-weight: bold;
    }
    p{
        text-align: end;
        margin: 0.4rem 2rem 0.4rem 0.4rem;;
        cursor: pointer;
        font-size: 0.8rem;
        color: var(--active-title);
        text-decoration: underline;
        font-weight: bold
    }
    button{
        height: 30px;
        width: 200px;
        // background: var(--active-title);
        // color: white;
        font-weight: 400;
        font-size: 0.9rem;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    .btnAni {
        font-weight: bold;
        background: linear-gradient(90deg, var(--text1), var(--text2), var(--text3), var(--text4));
        background-size: 300%;
        background-clip: border-box;
        -webkit-background-clip: border-box;
        // color: transparent; /* Make the text invisible to let the background show */
        border: none; /* Optional: style the border as needed */
        // padding: 10px 20px;
        border-radius: 5px; /* Optional: for rounded corners */
        animation: colorCycle 6s ease-in-out infinite;
    }
    
    @keyframes colorCycle {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}

@media screen and (max-width: $small-screen-size) {
    .leftScreen{
        display: none;
    }
    .loginScrinSplit{
        display: grid;
        grid-template-columns: 100%;
        height: 100vh;
    }
}