.footer{
    background: linear-gradient(90deg, var(--text), var(--subText));
    text-align: center;
    padding: 1px 0 10px;
    h1{
        font-weight: bold;
    }
    .footerPolicy{
        margin: 1rem 0;
        span{
            cursor: pointer;
        }
    }
    .copyWrite{
        font-size: 12px;
    }
}