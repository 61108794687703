$small-screen-size: 650px;
$mid-screen-size: 1000px;

.equipmentPopupBg{
    position: absolute;
    top: 0;
    left: 0;
    background: var(--popup-bg);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .equipmentPopup{
        position: relative;
        height: 370px;
        // overflow-y: auto;
        width: 30%;
        background: white;
        border-radius: 5px;
        padding: 0 15px 15px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        .equipmentTitle{
            font-weight: 900;
            text-align: center;
            margin-top: 10px;
            color: var(--active-title);
        }
        .equipmentBtn{
            border: none;
            height: 30px;
            width: 50px;
            cursor: pointer;
            margin-top: 10px;
            border-radius: 5px;
            background: var(--grey-color);
            font-weight: 600;
        }
        .deleteEquipment{
            border: none;
            height: 25px;
            width: 25px;
            border-radius: 15px;
            background:var(--grey-color);
            display: flex;
            align-items: center;
            // text-align: center;
            justify-content: center;
        }
        .cloaseButton{
            position: absolute;
            right: 0;
            top: 0;
            background: var(--grey-color);
            margin: 5px;
            cursor: pointer;
            border: none;
            height: 30px;
            width: 30px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .equipmentContainer{
            justify-content: center;
            margin-top: 10px;
            height: 200px;
            scrollbar-width: none;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .equipmentHeader{
            display:grid;
            grid-template-columns: 160px 60px 60px 30px;
            grid-gap: 5px;
            margin-top: 10px;
            text-align: center;
            div{
                font-weight: 700;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: $small-screen-size) {
        .equipmentPopup{
            width: 90%;
            // height: 80vh;
        }
      }
      @media screen and (min-width: $small-screen-size) and (max-width: $mid-screen-size) {
        .equipmentPopup{
            width: 50%;
        }
      }
    .equipmentList{
        display: grid;
        grid-template-columns: 160px 60px 60px 30px;
        grid-gap: 5px;
        align-items: center;
        .removeInput{
            // padding-left: 5px;
        }
    }
    .eName{
        height: 25px;
        margin-top: 5px;
        border: 2px solid var(--grey-color);
        border-radius: 5px;
    }
    .eBtnSize{
        height: 25px;
        margin: 5px 0 0 0px;
        border: 2px solid var(--grey-color);
        border-radius: 5px;
    }
}

.settingsForm{
    input{
        height: 30px;
        width: 300px;
        border: 2px solid var(--grey-color);
        border-radius: 5px;
    }
    .msgContainer{
        margin-top: 10px;
    }
    span{
        color: var(--active-title);
        font-size: 12px;
        font-weight: 700;
    }
    textarea{
        height: 50px;
        width: 302px;
        border: 2px solid var(--grey-color);
        border-radius: 5px;
    }
    button{
        height: 30px;
        width: 70px;
        background: var(--active-title);
        color: white;
        border-radius: 5px;
        border: none;
    }
}