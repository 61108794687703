.adminLogin{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    form{
        text-align: center;
        img{
            height: 4.5rem;
        }
        input{
            height: 2rem;
            width: 13rem;
            margin: .5rem;
        }
        button{
            margin: .5rem;
            height: 2.1rem;
            width: 13.5rem;
            background: var(--active-title);
            color: white;
            font-weight: 600;
            border: none;
        }
    }
}