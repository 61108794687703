.freeTrial{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--popup-bg);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .freeCard{
        padding: 10px;
        height: 150px;
        width: 350px;
        background: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        text-align: center;
        .trialLabel{
            color: var(--active-title);
            font-size: 17px;
            font-weight: 700;
        }
        .btnContainer{
            display: flex;
            justify-content: space-evenly;
            margin-top: 25px;
            .aTag{
                text-decoration: none;
            }
        }
        .trialBtn{
            // height: 35px;
            // width: 100px;
            padding: 10px 15px;
            color: white;
            background: var(--active-title);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            span{
                padding-left: 5px;
            }
        }
    }
}