.custDetail{
    .singleCust{
        margin: 0 0 2rem 0;
        padding: 0 0 0 .5rem;
        .detailContainer{
            display: flex;
            div{
                font-weight: 700;
            }
        }
        cursor: pointer;
    }
    .read{
        border-left: .3rem solid red;
    }
    .noRead{
        border-left: .3rem solid green;
    }
    .label{
        width: 6.5rem;
    }
}